
import { defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { ScrollComponent } from "../../../assets/ts/components";
import { setCurrentPageTitle } from "../../../helper/Breadcrumb";
import { SessionListModel } from "@/domain/hotel-management/session-list/model/SessionListModel";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { useI18n } from "vue-i18n";
import { AboutUserModel } from "@/domain/user/about-user/model/AboutUserModel";
import { timeAgo } from "@/presentation/helper/DateFormatter";

interface KTMessage {
  type: string;
  name?: string;
  image: string;
  time: string;
  text: string;
}

export default defineComponent({
  name: "private-chat",
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const isLoading = ref<boolean>(false);

    const sessionId = ref<any>(Number(route.params["id"]));

    const messagesRef = ref<null | HTMLElement>(null);
    const messagesInRef = ref<null | HTMLElement>(null);
    const messagesOutRef = ref<null | HTMLElement>(null);

    const swalNotification = new SwalNotification();

    const user = ref<AboutUserModel>({});

    const messages = ref<Array<KTMessage>>([]);
    const sessionTitle = ref<string>("");
    const sessionList = ref<SessionListModel[]>([]);
    const originalSessionList = ref<SessionListModel[]>([]);

    const sessionListModel: SessionListModel = {
      session: {
        productSessionMessages: [],
      },
      page: 1,
      pageSize: 50,
    };
    const search = ref<string>("");

    const userController = store.state.ControllersModule.userController;
    const sessionController = store.state.ControllersModule.sessionController;

    const newMessageText = ref("");

    watch(
      () => store.getters.newChat,
      () => {
        if (store.getters.newChat) getUserInfo();
        getSessionList();
      }
    );

    const getSessionList = () => {
      isLoading.value = true;

      sessionList.value = [];
      messages.value = [];

      if (search.value) {
        sessionListModel.query = search.value;
      } else {
        originalSessionList.value = [];
        sessionListModel.query = search.value;
      }

      sessionController
        .sessionList(sessionListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((session) => {
              sessionList.value.push(session);
              originalSessionList.value.push(session);

              if (session.session.id == sessionId.value) {
                sessionTitle.value = session.session.title;
                session.session.productSessionMessages.forEach((log, index) => {
                  messages.value.push({
                    type: log.owner === "USER" ? "out" : "in",
                    name: log.owner === "USER" ? undefined : "Asistan",
                    image:
                      log.owner === "USER"
                        ? "/media/avatars/blank.png"
                        : "/media/avatars/ai.jpg",
                    time: timeAgo(log.createdAt),
                    text: log.text,
                  });
                });
              }
            });
          } else {
            sessionList.value = [];
            originalSessionList.value = [];

            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getUserInfo = () => {
      userController
        .aboutUser()
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(user.value, response.getValue());
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const startNewSession = () => {
      store.dispatch("startNewSession", !store.getters.startNewSession);
    };

    onMounted(() => {
      getUserInfo();
      getSessionList();
      setCurrentPageTitle(t("modals.hotel.title"));

      if (!messagesRef.value) {
        return;
      }

      ScrollComponent.bootstrap();
    });

    return {
      timeAgo,
      startNewSession,
      messages,
      messagesRef,
      newMessageText,
      messagesInRef,
      messagesOutRef,
      sessionList,
      originalSessionList,
      sessionTitle,
      user,
      getSessionList,
      search,
      isLoading,
    };
  },
});
